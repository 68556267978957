import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./NosFantomes.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";

import Tile from "./../../../../res/Nos fantomes/fantomes-tile.png"
import InSitu1 from "./../../../../res/Nos fantomes/insitu-1.jpg";
import InSitu2 from "./../../../../res/Nos fantomes/insitu-2.jpg";
import InSitu3 from "./../../../../res/Nos fantomes/insitu-3.jpg";
import InSitu4 from "./../../../../res/Nos fantomes/insitu-4.jpg";
import InSitu5 from "./../../../../res/Nos fantomes/insitu-5.jpg";
import NosFantomesPdf from "./../../../../res/Nos fantomes/CP francais.docx.pdf";
import Carousel from 'react-multi-carousel';
const infos ={
  photos: [
    { src: InSitu1, name: "InSitu1" },
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu4, name: "InSitu4" },
  ],
};

const NosFantomesV2 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="Nos fantomes"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>NOS FANTÔMES<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="Nos fantomes"/>
        <br />
        <p>
        Thus are invoked the ghosts that inhabit Ghost Galerie’s primary DNA, the spectres of its passion for the initiators of American Graffiti. The first works on canvases by the major figures of New York Graffiti are the rare historical and physical evidence of the premises of a liberated and conquering expression of popular culture, which grew into a global phenomenon and kept its vital strength to this day. These Post-graffiti works, created by the artists that were most present in the streets and in the subways at the beginning of the 1980’s but were also invited to the most prominent New York galleries at the time (Sidney Janis, Annina Nosei and Tony Shafrazi), reveal how their individual practices evolved in the private atmosphere of the studio. These paintings on canvas, more personal in nature, allow us to see the artists’ own ghosts, those who live within their works. Some ghosts, historical or contemporary works, will disappear and appear by surprise throughout the exhibition and create the event in the event.</p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={NosFantomesPdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default NosFantomesV2;
