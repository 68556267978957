// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NosFantomes-module--CarrouselWrapper2--aF6Js";
export var DescriptionWrapper = "NosFantomes-module--DescriptionWrapper--QaqB+";
export var DownloaderWrapper = "NosFantomes-module--DownloaderWrapper--QKdXy";
export var EventWrapper = "NosFantomes-module--EventWrapper--nIpjb";
export var ImageWrapper = "NosFantomes-module--ImageWrapper--fpa4X";
export var ImagesWrapper = "NosFantomes-module--ImagesWrapper--VgSm1";
export var MobileTile = "NosFantomes-module--MobileTile--wQRfU";
export var PdpWrapper = "NosFantomes-module--PdpWrapper--HzzxY";
export var PhotosWrapper = "NosFantomes-module--PhotosWrapper--82Mjc";
export var TitleWrapper = "NosFantomes-module--TitleWrapper--iGz7j";
export var Wrapper = "NosFantomes-module--Wrapper--AZh0z";